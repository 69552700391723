import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import Flex, { FlexItem } from 'styled-flex-component';
import { map } from 'lodash';

import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import { LinkButton } from 'theme/ThemeProviderOverrides/Button';
import { variables } from './variables';
import { ButtonGroupWrapper } from './Button.styles';

const {
  spacing_xs: spacingXs,
  line_height_xxl: lineHeightXxl,
  color_white: colorWhite,
  custom_blue: customBlue,
  color_grey_500: colorGrey500,
} = variables;

export function Button({
  onResetButtonState,
  to,
  anchor,
  onClick,
  buttonStatus,
  error,
  loading,
  spinnerSize,
  children,
  solo,
  alignCenter,
  justifyCenter,
  icon,
  iconBefore,
  iconAfter,
  iconSize,
  iconColor,
  publicButton,
  disabled,
  fontAwesomeIcon,
  fontAwesomeIconPrefix,
  fontAwesomeIconBefore,
  fontAwesomeIconBeforePrefix,
  fontAwesomeIconAfter,
  fontAwesomeIconAfterPrefix,
  ...rest
}) {
  const statusTimer = useRef();
  const [buttonState, setButtonState] = useState(false);
  const { style, ...restMinusStyle } = rest;
  useEffect(() => {
    if (buttonStatus) {
      if (loading) {
        setButtonState('isLoading');
      } else if (buttonState === 'isLoading' && !loading && !error) {
        setButtonState('isSuccess');
        statusTimer.current = setTimeout(() => setButtonState(''), 2000);
      } else if (buttonState === 'isLoading' && !loading && error) {
        setButtonState('isError');
        statusTimer.current = setTimeout(() => setButtonState(''), 1000);
      } else {
        setButtonState('');
        if (onResetButtonState) {
          onResetButtonState();
        }
      }
    }
    return () => {
      clearInterval(statusTimer.current);
    };
  }, [loading, error, buttonState, buttonStatus, onResetButtonState]);

  const hasState = buttonState === 'isSuccess' || buttonState === 'isError';
  const Component = anchor ? LinkButton : ZendeskButton;
  return (
    <Component
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restMinusStyle}
      style={{
        ...style,
        cursor: style?.cursor || (disabled ? 'not-allowed' : undefined),
      }}
      onClick={loading || disabled ? null : onClick}
      loading={loading ? `${loading}` : undefined}
      publicButton={publicButton}
      buttonState={buttonState}
      disabled={disabled}
    >
      {publicButton ? (
        <Icon pointer color={colorWhite} icon="icon-arrow-right-short" />
      ) : null}
      {hasState ? (
        <Flex alignCenter justifyCenter style={{ marginLeft: `0px` }}>
          <FlexItem style={{ paddingRight: `${spacingXs}` }}>
            <Flex
              className="icon-flex-wrapper"
              style={{
                minHeight:
                  rest.size === 'large' ? lineHeightXxl : lineHeightXxl,
              }}
              alignCenter
              justifyCenter
            >
              {buttonState === 'isSuccess' ? (
                <Icon
                  pointer
                  color={colorWhite}
                  fontSize={iconSize || '20px'}
                  icon="icon-check-outline"
                />
              ) : (
                <Icon
                  pointer
                  color={colorWhite}
                  fontSize={iconSize || '20px'}
                  icon="icon-close-outline"
                />
              )}
            </Flex>
          </FlexItem>
        </Flex>
      ) : (
        <Flex
          alignCenter
          justifyCenter
          style={{
            marginLeft: `0px`,
            color: disabled ? colorGrey500 : undefined,
          }}
        >
          {loading ? (
            <FlexItem style={{ paddingRight: `${spacingXs}` }}>
              <Flex
                className="icon-flex-wrapper"
                style={
                  publicButton
                    ? {}
                    : {
                        minHeight:
                          rest.size === 'large' ? lineHeightXxl : lineHeightXxl,
                      }
                }
                alignCenter
                justifyCenter
              >
                <Spinner
                  color={rest.link ? rest.color || customBlue : colorWhite}
                  size={spinnerSize || '10px'}
                />
              </Flex>
            </FlexItem>
          ) : null}
          {iconBefore ? (
            <Icon
              pointer
              color={iconColor}
              fontSize={iconSize || '13px'}
              style={{ paddingLeft: '1px', paddingRight: `${spacingXs}` }}
              icon={iconBefore}
              fontAwesome={fontAwesomeIconBefore}
              iconPrefix={fontAwesomeIconBeforePrefix}
            />
          ) : null}
          {(loading && !solo) || !loading ? children : null}
          {iconAfter ? (
            <Icon
              pointer
              color={iconColor}
              fontSize={iconSize || '13px'}
              style={{ paddingRight: '1px', paddingLeft: `${spacingXs}` }}
              icon={iconAfter}
              fontAwesome={fontAwesomeIconAfter}
              iconPrefix={fontAwesomeIconAfterPrefix}
            />
          ) : null}
          {icon ? (
            <Icon
              pointer
              color={iconColor}
              fontSize={iconSize || '13px'}
              icon={icon}
              fontAwesome={fontAwesomeIcon}
              iconPrefix={fontAwesomeIconPrefix}
            />
          ) : null}
        </Flex>
      )}
      {anchor ? <Link to={to} /> : null}
    </Component>
  );
}

Button.defaultProps = {
  onResetButtonState: undefined,
  to: undefined,
  anchor: undefined,
  onClick: undefined,
  buttonStatus: undefined,
  error: undefined,
  loading: undefined,
  spinnerSize: undefined,
  children: undefined,
  solo: undefined,
  alignCenter: undefined,
  justifyCenter: undefined,
  icon: undefined,
  iconBefore: undefined,
  iconAfter: undefined,
  iconSize: undefined,
  iconColor: undefined,
  publicButton: undefined,
  disabled: undefined,
  fontAwesomeIcon: undefined,
  fontAwesomeIconBefore: undefined,
  fontAwesomeIconAfter: undefined,
  fontAwesomeIconPrefix: undefined,
  fontAwesomeIconBeforePrefix: undefined,
  fontAwesomeIconAfterPrefix: undefined,
};

Button.propTypes = {
  onResetButtonState: PropTypes.func,
  to: PropTypes.string,
  anchor: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStatus: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.bool,
  spinnerSize: PropTypes.string,
  children: PropTypes.node,
  solo: PropTypes.bool,
  alignCenter: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  icon: PropTypes.string,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  publicButton: PropTypes.bool,
  disabled: PropTypes.bool,
  fontAwesomeIcon: PropTypes.bool,
  fontAwesomeIconBefore: PropTypes.bool,
  fontAwesomeIconAfter: PropTypes.bool,
  fontAwesomeIconPrefix: PropTypes.string,
  fontAwesomeIconBeforePrefix: PropTypes.string,
  fontAwesomeIconAfterPrefix: PropTypes.string,
};

export function ButtonGroup({
  options,
  selected,
  onChangeSelected,
  ButtonComponent,
}) {
  const Component = ButtonComponent || Button;
  return (
    <ButtonGroupWrapper>
      {map(options, (option) => (
        <Component
          mini
          primary={selected === option}
          active={selected === option}
          onClick={() => onChangeSelected(option)}
          key={option}
        >
          {option}
        </Component>
      ))}
    </ButtonGroupWrapper>
  );
}

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.string,
  onChangeSelected: PropTypes.func,
  ButtonComponent: PropTypes.shape({}),
};

ButtonGroup.defaultProps = {
  options: undefined,
  selected: undefined,
  onChangeSelected: undefined,
  ButtonComponent: undefined,
};

export default Button;
